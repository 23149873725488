import config from "../../../config";
import React from "react";
import { Link } from "gatsby";
// import useUser from "../../lib/auth";
import {
  Box,
  Container,
  SimpleGrid,
  Heading,
  Text,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import ChakraLink from "../../components/elements/Link";
import Layout from "../../layouts/Layout";
import { getActiveDocumentationTypes } from "../../lib/documentation";

let activeDocumentationTypes = getActiveDocumentationTypes();

export default function Page() {
  const pageTitle = "";
  const meta = {
    // description: "",
    // ogTitle: "",
    // ogDescription: "",
    // ogType: "",
  };

  const hoverBgColor = useColorModeValue("blackAlpha.50", "whiteAlpha.50");
  // const { loading, isAuthenticated } = useUser({ required: true });
  // if (loading || !isAuthenticated) return null;
  // console.log(isAuthenticated());

  return (
    <Layout pageTitle={pageTitle} meta={meta}>
      <Container maxW="container.xl" my={14} maxHeight="80vh" overflowY="auto">
        <Box textAlign="center" mt={0}>
          <Text color="black" fontSize="lg">
            Welcome to the MovieLabs Documentation site for Media Creation
            initiatives. This site contains detailed technical documentation for
            those looking to implement parts of the 2030 Vision for the future
            of Media Creation. For more information about MovieLabs or the 2030
            Vision visit{" "}
            <ChakraLink
              href="https://www.movielabs.com"
              target="_blank"
              color="blue.500"
            >
              movielabs.com
            </ChakraLink>
            . <br />
            <br />
            This site is organized into four broad categories, select one to
            explore the documentation. A full revision history is included, but
            we default to the latest released version.
            <br />
            <br />
            <br />
          </Text>
        </Box>

        <SimpleGrid minChildWidth="300px" spacing="40px">
          {activeDocumentationTypes.map((item, index) => (
            <Box
              key={index}
              as={Link}
              to={item.path}
              textAlign="center"
              p={10}
              borderWidth={1}
              borderRadius={5}
              transition="transform 500ms ease"
              _hover={{
                bgColor: hoverBgColor,
                transform: "scale(1.05)",
              }}
            >
              {item.icon && (
                <Image
                  src={item.icon}
                  alt={item.title}
                  boxSize="70px"
                  objectFit="contain"
                  objectPosition="center"
                  m="0 auto 25px"
                />
              )}
              <Box maxW="270px" m="0 auto">
                <Heading as="h2" fontSize="2xl" fontWeight="bold" mb={2}>
                  {item.title}
                </Heading>
                <Text color="gray.600" fontSize="lg">
                  {item.description}
                </Text>
              </Box>
            </Box>
          ))}
          <Box
            as={Link}
            to={config.vocabulary.types[0].path}
            textAlign="center"
            p={10}
            borderWidth={1}
            borderRadius={5}
            transition="transform 500ms ease"
            _hover={{
              bgColor: hoverBgColor,
              transform: "scale(1.05)",
            }}
          >
            {config.vocabulary.types[0].icon && (
              <Image
                src={config.vocabulary.types[0].icon}
                alt={config.vocabulary.types[0].title}
                boxSize="70px"
                objectFit="contain"
                objectPosition="center"
                m="0 auto 25px"
              />
            )}
            <Box maxW="270px" m="0 auto">
              <Heading as="h2" fontSize="2xl" fontWeight="bold" mb={2}>
                {config.vocabulary.types[0].title}
              </Heading>
              <Text color="gray.600" fontSize="lg">
                {config.vocabulary.types[0].description}
              </Text>
            </Box>
          </Box>
        </SimpleGrid>

        <Box textAlign="center" mt={20}>
          <Text color="gray.600" fontSize="lg">
            Learn more about Motion Picture Laboratories, Inc. at{" "}
            <ChakraLink
              href="https://www.movielabs.com"
              target="_blank"
              color="blue.500"
            >
              movielabs.com
            </ChakraLink>
            .
          </Text>
        </Box>
      </Container>
    </Layout>
  );
}
